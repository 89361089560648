import React from "react";
import { get } from "lodash";
import SEO from "@components/seo";
import Layout from "@components/layout.js";
import Header from "@components/header/index.js";
import "@scss/vote.scss";

const VotePage = ({ data }) => {
  return (
    <Layout>
      <SEO title="Shop" />
      <Header isInterior={true} />
      <div className="vote">
        <h2>
          Digital Drag Fest
          <br />
          Awards
        </h2>
        <div className="vote--iframe">
          <iframe
            src="//www.surveygizmo.com/s3/5586649/Digital-Drag-Fest-Award-Final-Round"
            frameborder="0"
            height="100%"
            widht="100%"
            style={{ overflow: "hidden", width: "100%", height: "100vh" }}
          />
        </div>
      </div>
    </Layout>
  );
};

export default VotePage;
